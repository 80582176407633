html, body {
  color: #444;
}


.blueBg {
  background-color: #498bc4;
  color: #fff;
}

.greyBg {
  background-color: #6e6b6f;
  color: #fff;
}

.footerClass {
  min-height: 125px;
}

.buttonHover:hover {
  transition: 0.5s;
  background-color: #6e6b6f!important;
}

/*
.sep {
  min-height: 800px;
  background-color: #498bc4;
  animation: bgColor alternate 10s ease infinite;
  transform: rotate(130deg) scale(3rem);
}
*/

@keyframes bgColor {
  0% {
    background-color: #f7cc2c;
  }

  25% {
    background-color: #6e6b6f;
  }

  50% {
    background-color: #fff;
  }

  100% {
    background-color: #498bc4;
  }
}

.animSpin {
  animation: animSpin 0.5s;
}

@keyframes animSpin {
  0%{
    transform: rotate(720deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.mobileHover {
  background-color: #498bc4;
  transition: 0.5s;
}

.mobileHover:hover {
  color: #333!important;
  background-color: #f7cc2c;
  transition: 0.5s;
}

.footerHover:hover {
  background-color: #fff3;
}


.dynImage {
  max-width: 450px;
  cursor: pointer;
}

@media (max-width: 450px) {
  .dynImage {
    max-width: 100%;
  }
  .modalGrow {
    width: 100%;
  }
}

@media (min-width: 451px) {
  
}


.borderAnimation {
  border-radius: 10px;
  border: 1px solid #f7cc2c;
  animation: borderAnim 5s ease alternate-reverse infinite;
}

@keyframes borderAnim {
  0% {
    border-color: #f7cc2c;
  }
  100% {
    border-color: #498bc4;
  }
}