@-webkit-keyframes Animation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}
@-moz-keyframes Animation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}
@keyframes Animation { 
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}

.gradient
{
    height:90px;
    font-size:30px;
    background: linear-gradient(130deg, #f7cc2c, #ffffff, #498bc4);
    background-size: 200% 200%;
    -webkit-animation: Animation 10s ease infinite;
    -moz-animation: Animation 10s ease infinite;
    animation: Animation 10s ease infinite;
}


.bgAnim {
    background:radial-gradient(ellipse at center, rgba(255,254,234,1) 0%, rgba(255,254,234,1) 35%, #B7E8EB 100%);
    overflow: hidden;
}

.gradientBack
{
    height:90px;
    font-size:30px;
    background: linear-gradient(130deg, #498bc4, #ffffff, #f7cc2c);
    background-size: 200% 200%;
    -webkit-animation: Animation 10s ease infinite;
    -moz-animation: Animation 10s ease infinite;
    animation: Animation 10s ease infinite;
}
